import { userServer } from "./User/server";
import { HttpResponse } from "serviceUtils/ct-library-http-client";
import { Credential } from "serviceUtils/ct-library-credential";
import { signingRequestServer } from "./Signing-request/server";
import { connectionServer } from "./Connection/server";

export type API = () => Promise<HttpResponse<any, Error>>;

const serverListForCredential = {
  signingRequest: signingRequestServer,
  connection: connectionServer,
  user: userServer,
};
export const credential = new Credential(serverListForCredential);

export const servers = {
  ...serverListForCredential,
};
