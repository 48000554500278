import { CredentialObserver } from "serviceUtils/ct-library-credential/index.type";
import {
  Api,
  ContentType,
  RequestParams,
} from "serviceUtils/ct-library-http-client";
import {
  createLogoutRequest,
  createLogoutResponse,
  createRefreshTokenRequest,
  createRefreshTokenResponse,
  fetchOAuthRequest,
  fetchOAuthResponse,
  fetchOAuthServiceDetailRequest,
  fetchOAuthServiceDetailResponse,
} from "./index.type";

export class UserAPI extends Api implements CredentialObserver {
  constructor(baseUrl: string) {
    super({ baseUrl });
  }

  updateAccessToken(access_token: string): void {
    this.updateHeaders({ Authorization: `Bearer ${access_token}` });
  }
  onAccessTokenChange(access_token: string) {
    this.updateAccessToken(access_token);
  }
  updateApiKey(api_key: string): void {
    this.updateHeaders({ "x-eq-ag-api-key": api_key });
  }

  onAPIKeyChange(api_key: string) {
    this.updateApiKey(api_key);
  }
  /**
   * No description
   *
   * @name fetchOAuthServiceDetail
   * @summary User
   * @request GET:/
   */
  fetchOAuthServiceDetail = async (
    request: fetchOAuthServiceDetailRequest,
    params: RequestParams = {}
  ) =>
    await this.request<fetchOAuthServiceDetailResponse, Error>({
      path: `/oauth2`,
      method: "GET",
      format: "json",
      query: request.query,
      type: ContentType.Json,
      ...params,
    });

  /**
   * No description
   *
   * @name OAuth
   * @summary User
   * @request GET:/
   */
  OAuth = async (request: fetchOAuthRequest, params: RequestParams = {}) => {
    return await this.request<fetchOAuthResponse, Error>({
      path: `/oauth2/authorize`,
      method: "GET",
      format: "json",
      query: request.query,
      credentials: "include",
      ...params,
    });
  };

  /**
   * No description
   *
   * @name logout
   * @summary User
   * @request GET:/
   */
  logout = async (request: createLogoutRequest, params: RequestParams = {}) =>
    await this.request<createLogoutResponse, Error>({
      path: `/users/logout`,
      method: "POST",
      format: "json",
      query: request.query,
      type: ContentType.Json,
      ...params,
    });

  /**
   * No description
   *
   * @name refreshToken
   * @summary User
   * @request GET:/
   */
  refreshToken = async (
    request: createRefreshTokenRequest,
    params: RequestParams = {}
  ) =>
    await this.request<createRefreshTokenResponse, Error>({
      path: `/user/refreshToken`,
      method: "POST",
      format: "json",
      body: request.data,
      type: ContentType.Json,
      ...params,
    });
}
export const userServer = new UserAPI(
  process.env.REACT_APP_CONNECT_WEB_END_POINT as string
);
