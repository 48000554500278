import React, { useMemo } from "react";
import ConnectModuleTemplate from "../../../common/template/ConnectModuleTemplate";
import { CONNECTION_TYPE } from "../../../Utils/constant";

import ConnectByWhisper from "./ConnectByWhisper";
import { isMobile } from "react-device-detect";
import CheckOAuthPermission from "../../oauth/CheckOAuthPermission";
import SelectConnectType from "./SelectConnectType";
import { useSelector } from "react-redux";
import { connection_type } from "../../../services/Connection/reedux";
import { user_access } from "../../../services/User/redux";

function ConnectionMain({ requestFrom }) {
  const userAccess = useSelector(user_access);
  const connectionType = useSelector(connection_type);
  const connectionRenderer = useMemo(() => {
    switch (connectionType) {
      case CONNECTION_TYPE.CONNECT_TYPE_SELECT:
        return <SelectConnectType connectionType={connectionType} />;
      case CONNECTION_TYPE.CONNECT_TYPE_WHISPER:
        return <ConnectByWhisper requestFrom={requestFrom} />;
      case CONNECTION_TYPE.CONNECT_CHECK_OAUTH_PERMISSION:
        return <CheckOAuthPermission />;
      default:
        return <>invalid access</>;
    }
  }, [connectionType]);

  const closeConnectModule = () => {
    window.close();
  };
  return (
    <>
      <ConnectModuleTemplate
        onClickClose={closeConnectModule}
        userAccess={userAccess}
      >
        {connectionRenderer}
      </ConnectModuleTemplate>
    </>
  );
}

export default ConnectionMain;
