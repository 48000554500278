import React from "react";
import "./SelectConnectType.scss";
import SelectConnectTypeCard from "../molecules/SelectConnectTypeCard";
import { CONNECTION_TYPE } from "../../../Utils/constant";
import useTranslationCustom from "../../../locales/useTranslationCustom";
import { project_id } from "../../../services/AppSetting/redux";
import { useSelector } from "react-redux";

function SelectConnectType(props) {
  const projectId = useSelector(project_id);
  const { t } = useTranslationCustom({
    prefix: "",
    nameSpace: "connect_type",
  });

  const connectType = [
    {
      id: "QR",
      cardType: "blue",
      mainText: t("qr.main_text"),
      subText: t("qr.description"),
      Icon: "connectTypeCompanyIcon",
      connectionType: CONNECTION_TYPE.CONNECT_TYPE_WHISPER,
    },
  ];
  //TODO :: 다른 whiteLabeling App이 추가로 존재 할 시 딥링크 설정
  const navigateToPlayStore = () => {
    switch (projectId) {
      case Number(process.env.REACT_APP_WHISPER_PROJECT_ID):
        window.open(
          "https://play.google.com/store/apps/details?id=com.eqbr.whisper.app",
          "_blank"
        );
        break;
      case Number(process.env.REACT_APP_EON_PROJECT_ID):
        // TODO :: EON 프로젝트에 대한 딥링크 추가
        break;
      default:
        window.open(
          "https://play.google.com/store/apps/details?id=com.eqbr.whisper.app",
          "_blank"
        );
    }
  };
  const navigateToAppStore = () => {
    switch (projectId) {
      case Number(process.env.REACT_APP_WHISPER_PROJECT_ID):
        window.open(
          "https://apps.apple.com/kr/app/whisper-msg/id1592954310",
          "_blank"
        );
        break;
      case Number(process.env.REACT_APP_EON_PROJECT_ID):
        // TODO :: EON 프로젝트에 대한 딥링크 추가
        break;
      default:
        window.open(
          "https://apps.apple.com/kr/app/whisper-msg/id1592954310",
          "_blank"
        );
    }
  };

  return (
    <div className={"select-connect-type"}>
      <div className="main-image-container">
        <img className="main-logo companyLogoWithText" alt="logo" />
        <div className="store-image-container">
          <img
            className="store-icon playstoreIcon"
            alt="playstore"
            onClick={() => navigateToPlayStore()}
          />
          <img
            className="store-icon appstoreIcon"
            alt="appstore"
            onClick={() => navigateToAppStore()}
          />
        </div>
      </div>
      <div style={{ height: 20 }} />
      {connectType.map((type) => {
        return <SelectConnectTypeCard key={type.id} cardData={type} />;
      })}
    </div>
  );
}

export default SelectConnectType;
