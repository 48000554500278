import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en_whisper from "./launges/whisper/en.json";
import ko_whisper from "./launges/whisper/ko.json";
import en_EON from "./launges/eon/en.json";
import ko_EON from "./launges/eon/ko.json";
import en_bonego from "./launges/bonego/en.json";
import ko_bonego from "./launges/bonego/ko.json";

const resources = {
  en_whisper,
  ko_whisper,
  en_EON,
  ko_EON,
  en_bonego,
  ko_bonego
};

// Get supported languages
export function getLanguages() {
  return {
    ko: "한국어",
    en: "English"
  };
}

let locale = navigator.language || navigator.userLanguage;

const sessionLan = sessionStorage.getItem("language");

if (sessionLan) {
  locale = sessionLan;
} else {
  switch (locale) {
    case "ko":
    case "ko-KR":
      locale = "ko";
      break;
    case "en":
    case "en-US":
      locale = "en";
      break;
    default:
  }
}

export const changeWordsByAppName = async (appName) => {
  let locale = navigator.language;

  const sessionLan = sessionStorage.getItem("language");

  if (sessionLan) {
    locale = sessionLan;
  } else {
    switch (locale) {
      case "ko":
      case "ko-KR":
        locale = "ko";
        break;
      case "en":
      case "en-US":
        locale = "en";
        break;
      default:
        locale = "en";
    }
  }
  await i18n.changeLanguage(locale + "_" + appName);
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: locale,
  debug: true,
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  }
});

export default i18n;
