import { createSlice } from "@reduxjs/toolkit";
import { logout, refreshToken } from "../server";

// Adapter 영역 - 선언부
const initialState = {
  address_user: {
    id: null,
    address: "",
    nickname: "",
    profile_image: null,
    whisper_image: null,
    key: {
      public_key: "",
      box_public_key: "",
    },
  },
  token: {
    access: {
      expires: "",
      token: null,
    },
    refresh: {
      expires: "",
    },
  },
  refresh_token: "",
  logout: "",
};

// Slice 영역 - 선언부
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInfo(state, action) {
      Object.keys(action.payload).forEach((el) => {
        state[el] = action.payload[el];
      });
    },
    setRefreshToken(state, action) {
      Object.keys(action.payload).forEach((el) => {
        state[el] = action.payload[el];
      });
    },
    setLogout(state, action) {
      Object.keys(action.payload).forEach((el) => {
        state[el] = action.payload[el];
      });
    },
  },
});

// Action 영역
export const { setUserInfo } = userSlice.actions;
export const { setRefreshToken } = userSlice.actions;
export const { setLogout } = userSlice.actions;

// Store 영역
export const user_info = (state) => state.user;
export const user_access = (state) => state.user.token.access.token;
export const user_refresh_token = (state) => state.user.refresh_token;
export const user_logout = (state) => state.user.logout;

// Reducer 영역
export default userSlice.reducer;
