import {createSlice} from "@reduxjs/toolkit";

// Adapter 영역 - 선언부
const initialState = {
  type: null
};

// Slice 영역 - 선언부
const connectionSlice = createSlice({
  name: "connection",
  initialState,
  reducers: {
    setConnectionType(state, action) {
      state.type = action.payload;
    }
  }
});

// Action 영역
export const {setConnectionType} = connectionSlice.actions;

// Store 영역
export const connection_type = state => state.connection.type;

// Reducer 영역
export default connectionSlice.reducer;
