import { controllers } from "controllers";
import QRCodeStyling from "qr-code-styling";
import { useEffect, useMemo, useRef, useState } from "react";
import { isIOS, isMobile } from "react-device-detect";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import whisperQrLogo from "../../../common/assets/images/whisper/QR_company_logo.png";
import eonQrLogo from "../../../common/assets/images/eon/QR_company_logo.png";
import bonegoQrLogo from "../../../common/assets/images/bonego/QR_company_logo.png";
import usePalette from "../../../common/styles/Colors/usePalette";
import useTranslationCustom from "../../../locales/useTranslationCustom";
import { app_name, project_id } from "../../../services/AppSetting/redux";
import { setUserInfo } from "../../../services/User/redux";
import { CONNECTION_STATUS, MESSAGE_TARGET } from "../../../Utils/constant";
import "./ConnectByWhisper.scss";
import { AppName } from "env";

function ConnectByWhisper({ requestFrom }) {
  const projectId = useSelector(project_id);
  const appName = useSelector(app_name);
  const Colors = usePalette();
  const { t } = useTranslationCustom({
    prefix: "",
    nameSpace: "connect_qr",
  });

  const qrCode = useMemo(() => {
    const getQrCompanyLogo = (appName) => {
      switch (appName) {
        case AppName.whisper:
          return whisperQrLogo;
        case AppName.EON:
          return eonQrLogo;
        case AppName.bonego:
          return bonegoQrLogo;
        default:
          return whisperQrLogo;
      }
    };
    const qrCompanyLogo = getQrCompanyLogo(appName);
    const qrStyle = {
      width: 150,
      height: 150,
      margin: 0,
      qrOptions: { typeNumber: 0, mode: "Byte", errorCorrectionLevel: "Q" },
      imageOptions: { hideBackgroundDots: true, imageSize: 0.4 },
      dotsOptions: {
        type: "extra-rounded",
        color: Colors.Surface_Normal_AbsoluteW,
      },
      backgroundOptions: { color: Colors.Surface_Brand_QR },
      image: qrCompanyLogo,
      dotsOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: "#6a1a4c",
          color2: "#6a1a4c",
          rotation: "0",
        },
      },
      cornersSquareOptions: {
        type: "extra-rounded",
        color: Colors.Surface_Normal_AbsoluteW,
      },
      cornersSquareOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: "#000000",
          color2: "#000000",
          rotation: "0",
        },
      },
      cornersDotOptions: { type: "", color: Colors.Surface_Normal_AbsoluteW },
      cornersDotOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: "#000000",
          color2: "#000000",
          rotation: "0",
        },
      },
      backgroundOptionsHelper: {
        colorType: { single: true, gradient: false },
        gradient: {
          linear: true,
          radial: false,
          color1: "#ffffff",
          color2: "#ffffff",
          rotation: "0",
        },
      },
    };
    return new QRCodeStyling(qrStyle);
  }, [appName]);
  const dispatch = useDispatch();
  const qrRef = useRef(null);

  const [connectionId, setConnectionId] = useState("");
  const [connectionStatus, setConnectionStatus] = useState(0);
  const [isConnected, setIsConnected] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [user, setUser] = useState(null);
  const timer = useMemo(() => {
    return {
      minutes: timeRemaining > 0 ? parseInt((timeRemaining / 60) % 60, 10) : 0,
      seconds: timeRemaining > 0 ? timeRemaining % 60 : 0,
    };
  }, [timeRemaining]);

  const createConnection_ = async () => {
    // return unwrapResult(
    //   await dispatch(
    //     createConnection({
    //       domain: document.referrer
    //         ? document.referrer
    //         : window.location.href,
    //       projectId: project,
    //     })
    //   )
    // );
    const domain = document.referrer || window.location.href;
    const { result, error } = await controllers.connection.createConnection(
      domain,
      projectId
    );
    if (result) {
      return result;
    }
    if (error) {
      console.log(error);
    }
    return;
  };

  const makeQRCodeByConnectionId = async () => {
    const { connection_id, expired_at } = await createConnection_();
    if (connection_id) {
      if (isMobile) {
        navigateToWhisper(connection_id);
        // todo:: added by jaetak
        setConnectionId(connection_id);
        setTimeRemaining(90);
      } else {
        setConnectionId(connection_id);
        setTimeRemaining(90);
      }
    }
  };

  const getConnectionResult = async (connectionId) => {
    // const { connection_status, address_user, token } = unwrapResult(
    //   await dispatch(
    //     pollingConnection({
    //       connectionId,
    //       projectId: project,
    //     })
    //   )
    // );
    const { result, error } = await controllers.connection.pollingConnection(
      projectId,
      connectionId
    );

    if (result) {
      const { connection_status, address_user, token } = result;
      if (connection_status === CONNECTION_STATUS.CONNECTION_SUCCESS) {
        if (requestFrom !== "EQBR")
          dispatch(setUserInfo({ address_user, connection_status, token }));
        else {
          setUser({ address_user, token });
        }
      }
      setConnectionStatus(connection_status);
    }

    if (error) {
      console.log(error.message);
    }
  };
  //TODO :: 다른 whiteLabeling App이 추가로 존재 할 시 딥링크 설정
  const navigateToWhisper = (connection_id) => {
    switch (projectId) {
      case Number(process.env.REACT_APP_WHISPER_PROJECT_ID):
        if (isIOS) {
          window.location.href = `whisper://user/request-connection/${connection_id}`;
        } else {
          window.open(`whisper://user/request-connection/${connection_id}`);
        }
        break;

      case Number(process.env.REACT_APP_EON_PROJECT_ID):
        if (isIOS) {
          window.location.href = `EON://user/request-connection/${connection_id}`;
        } else {
          window.open(`EON://user/request-connection/${connection_id}`);
        }
        break;
      case Number(process.env.REACT_APP_BONEGO_PROJECT_ID):
        if (isIOS) {
          window.location.href = `bonego://user/request-connection/${connection_id}`;
        } else {
          window.open(`bonego://user/request-connection/${connection_id}`);
        }
        break;
      default:
        if (isIOS) {
          window.location.href = `whisper://user/request-connection/${connection_id}`;
        } else {
          window.open(`whisper://user/request-connection/${connection_id}`);
        }
    }
  };

  useEffect(() => {
    makeQRCodeByConnectionId();
  }, []);

  useEffect(() => {
    if (!isMobile) {
      qrCode.append(qrRef.current);
    }
  }, []);

  useEffect(() => {
    if (!isMobile) {
      if (Boolean(connectionId)) {
        qrCode.update({
          data: connectionId,
        });
      }
    }
  }, [connectionId]);

  useEffect(() => {
    if (Boolean(timeRemaining)) {
      const ticker = setInterval(() => {
        setTimeRemaining((prevState) => {
          return prevState - 1;
        });
      }, 1000);
      return () => {
        clearInterval(ticker);
      };
    }
  }, [timeRemaining]);

  useEffect(() => {
    if (Boolean(connectionId)) {
      const polling = setInterval(async () => {
        if (connectionStatus === CONNECTION_STATUS.CONNECTION_PENDING) {
          await getConnectionResult(connectionId);
        } else {
          switch (connectionStatus) {
            case CONNECTION_STATUS.CONNECTION_SUCCESS:
              clearInterval(polling);
              return;
            case CONNECTION_STATUS.CONNECTION_FAILED_2:
            case CONNECTION_STATUS.CONNECTION_FAILED_3:
            default:
              clearInterval(polling);
              return;
          }
        }
      }, 1000);
      if (timeRemaining === 0) {
        clearInterval(polling);
      }
      return () => {
        clearInterval(polling);
      };
    }
  }, [connectionId, connectionStatus, timeRemaining]);

  useEffect(() => {
    switch (connectionStatus) {
      case CONNECTION_STATUS.CONNECTION_PENDING:
        return;
      case CONNECTION_STATUS.CONNECTION_SUCCESS:
        setIsConnected(true);
        return;
      case CONNECTION_STATUS.CONNECTION_FAILED_2:
        return toast.error("Connection failed. Please try again");
      case CONNECTION_STATUS.CONNECTION_FAILED_3:
        return toast.error("Connection failed. Please try again");
      default:
        return toast.error("Connection failed. Please try again");
    }
  }, [connectionStatus]);

  useEffect(() => {
    if (connectionStatus !== CONNECTION_STATUS.CONNECTION_PENDING) {
      setTimeRemaining(0);
    }
  }, [connectionStatus]);

  useEffect(() => {
    if (
      connectionStatus === CONNECTION_STATUS.CONNECTION_PENDING &&
      timeRemaining === 0
    ) {
      if (isMobile) {
        toast.success("Connection has been updated");
      } else {
        toast.success(t("updated_web"));
      }
      makeQRCodeByConnectionId();
    }
  }, [timeRemaining, connectionStatus]);

  useEffect(() => {
    //문제
    if (isConnected) {
      if (requestFrom === "EQBR") {
        if (window.opener) {
          //현재 창을 연 부모 창이 존재할 때
          window.opener.postMessage(
            {
              target: MESSAGE_TARGET.EQBR_CONNECTION,
              payload: user,
            },
            "*"
          );
        } else {
          console.error("No opener found.");
        }
        window.close();
      }
      // if (requestFrom === "EQBR") {
      //   window.opener.postMessage(
      //     {
      //       target: MESSAGE_TARGET.EQBR_CONNECTION,
      //       payload: user,
      //     },
      //     "*"
      //   );
      //   window.close();
      // }
    }
  }, [isConnected]);

  return (
    <div id="ConnectByWhisper">
      {isMobile ? (
        <div className="flex justify-center align-center">
          <div
            style={{ width: "100%", justifyContent: "center" }}
            className="high-emphasis"
          >
            Waiting for connection...
          </div>
          {/*{*/}
          {/*  isIOS ?*/}
          {/*    <div style={{width: '100%', justifyContent: 'center'}}>Waiting for connection...</div>*/}
          {/*    :*/}
          {/*    <div style={{width: '100%', justifyContent: 'center'}}>*/}
          {/*      <a ref={aRef}*/}
          {/*         href={`intent://user/request-connection/${connectionId}#Intent;scheme=whisper;package=com.eqbr.whisper.app;end`}>*/}
          {/*        Connect your wallet with Whisper*/}
          {/*      </a>*/}
          {/*    </div>*/}
          {/*}*/}
        </div>
      ) : (
        <>
          <div className="connect-by-whisper-header high-emphasis">
            {t("title")}
          </div>
          <div style={{ height: 20 }} />
          <div className="qr-code-container">
            <div
              style={{ width: 150, height: 150, borderRadius: 16 }}
              ref={qrRef}
            />
          </div>
          <div style={{ height: 24 }} />
          <div className="valid-timer">
            <span style={{ marginRight: 10 }} className="high-emphasis">
              {t("remain_time")}
            </span>
            {Boolean(timer.minutes) && (
              <div
                className="valid-time min high-emphasis"
                style={{ marginRight: 6 }}
              >
                {`${timer.minutes}m`}
              </div>
            )}
            <div className="valid-time sec high-emphasis">{`${timer.seconds}s`}</div>
          </div>
          <div style={{ height: 16 }} />
          <div className="connect-by-whisper-comment high-emphasis">
            {t("comment")}
          </div>
          <div style={{ height: 16 }} />
        </>
      )}
    </div>
  );
}

export default ConnectByWhisper;
