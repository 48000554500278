export const CONNECTION_STATUS = {
  CONNECTION_PENDING: 0,
  CONNECTION_SUCCESS: 1,
  CONNECTION_FAILED_2: 2,
  CONNECTION_FAILED_3: 3,
};
export const MESSAGE_TARGET = {
  EQBR_CONNECTION: "EQBR_CONNECTION",
  EQBR_SIGNING_REQUEST: "EQBR_SIGNING_REQUEST",
  EQBR_SIGNING_POLLING_RESPONSE: "EQBR_SIGNING_POLLING_RESPONSE",
};
export const SIGNING_REQUEST_POLLING_STATUS = {
  POLLING_PENDING: "POLLING_PENDING",
  POLLING_SUCCESS: "POLLING_SUCCESS",
  POLLING_FAIL: "POLLING_FAIL",
};
export const CONNECTION_TYPE = {
  CONNECT_TYPE_SELECT: "CONNECT_TYPE_SELECT",
  CONNECT_TYPE_WHISPER: "CONNECT_TYPE_WHISPER",
  CONNECT_TYPE_PIN: "CONNECT_TYPE_PIN",
  CONNECT_CHECK_OAUTH_PERMISSION: "CONNECT_CHECK_OAUTH_PERMISSION",
};
export const SERVER = {
  V1_API_GATEWAY: `${process.env.REACT_APP_HUB_SERVER_URL}${process.env.REACT_APP_V1_API_VERSION}`,
  V2_API_GATEWAY: `${process.env.REACT_APP_HUB_SERVER_URL}${process.env.REACT_APP_V2_API_VERSION}`,
};
