import React, { useMemo } from "react";
import "./SelectConnectTypeCard.scss";
import toast from "react-hot-toast";
import { CONNECTION_TYPE } from "../../../Utils/constant";
import { useDispatch } from "react-redux";
import { setConnectionType } from "../../../services/Connection/reedux";
import usePalette from "../../../common/styles/Colors/usePalette";

function SelectConnectTypeCard({ cardData }) {
  const { cardType, route, activate, ...data } = cardData || {};
  const dispatch = useDispatch();
  const navigateToSelectedType = (type) => {
    if (type.connectionType !== CONNECTION_TYPE.CONNECT_TYPE_WHISPER) {
      toast.success("Coming Soon!");
    } else {
      dispatch(setConnectionType(type.connectionType));
    }
  };

  const Colors = usePalette();
  const selectConnectTypeStyleHandler = useMemo(() => {
    switch (cardType) {
      case "blue":
        return {
          card: {
            backgroundColor: Colors.Surface_Brand_Primary,
          },
          mainText: {
            color: Colors.TextIcon_OnPrimary_HighEmp,
          },
          subText: {
            color: Colors.TextIcon_OnPrimary_HighEmp,
          },
        };
      case "white":
        return {
          card: {
            background: Colors.Surface_Normal_Container0,
            border: "1px solid",
            borderColor: Colors.Surface_Normal_Container0, //#E1E7F0, 디자인팀에서 색상 선언 필요
          },
          mainText: {
            color: Colors.TextIcon_OnSurface_HighEmp, // #404042, 디자인팀에서 색상 선언 필요
          },
          subText: {
            color: Colors.TextIcon_OnSurface_HighEmp, // #96A1BB, 디자인팀에서 색상 선언 필요
          },
        };
      default:
        return null;
    }
  }, [cardType]);
  return (
    <div
      id="SelectConnectTypeCard"
      style={{ ...selectConnectTypeStyleHandler.card }}
      onClick={() => navigateToSelectedType(cardData)}
    >
      <div className="connect-type-text-wrapper">
        <div
          className="connect-type-main-text"
          style={{ ...selectConnectTypeStyleHandler.mainText }}
        >
          {data.mainText}
        </div>
        <div style={{ height: 5 }} />
        <div
          className="connect-type-sub-text"
          style={{ ...selectConnectTypeStyleHandler.subText }}
        >
          {data.subText}
        </div>
      </div>
      {data.Icon && (
        <img className={`connect-type-icon ${data.Icon}`} alt="connect-icon" />
      )}
    </div>
  );
}

export default SelectConnectTypeCard;
