export enum AppName {
  whisper = "whisper",
  EON = "EON",
  bonego = "bonego",
}

const METADATA_ROOT_PATH = "/metadata/";
const METADATA = {
  whisper: {
    title: `${AppName.whisper} - connect to dApps`,
    description:
      "Connect your blockchain wallet to interact with web applications.",
    favicon: `${METADATA_ROOT_PATH}${AppName.whisper}/favicon.ico`,
    manifest: `${METADATA_ROOT_PATH}${AppName.whisper}/manifest.json`,
    logo192: `${METADATA_ROOT_PATH}${AppName.whisper}logo192.png`,
    logo512: `${METADATA_ROOT_PATH}${AppName.whisper}logo512.png`,
  },
  EON: {
    title: `${AppName.EON} - connect to dApps`,
    description:
      "Connect your blockchain wallet to interact with web applications.",
    favicon: `${METADATA_ROOT_PATH}${AppName.EON}/favicon.ico`,
    manifest: `${METADATA_ROOT_PATH}${AppName.EON}/manifest.json`,
    logo192: `${METADATA_ROOT_PATH}${AppName.EON}/logo192.png`,
    logo512: `${METADATA_ROOT_PATH}${AppName.EON}/logo512.png`,
  },
  bonego: {
    title: `${AppName.bonego} - connect to dApps`,
    description:
      "Connect your blockchain wallet to interact with web applications.",
    favicon: `${METADATA_ROOT_PATH}${AppName.bonego}/favicon.ico`,
    manifest: `${METADATA_ROOT_PATH}${AppName.bonego}/manifest.json`,
    logo192: `${METADATA_ROOT_PATH}${AppName.bonego}/logo192.png`,
    logo512: `${METADATA_ROOT_PATH}${AppName.bonego}/logo512.png`,
  },
};

export default METADATA;
