import { useEffect, useMemo, useState } from "react";
import ConnectionMain from "../service/connection/page/ConnectionMain";
import SigningRequest from "../service/signing-request/SigningRequest";
import Layout from "./Layout";

import PageLoading from "../common/atom/loading/PageLoading";
import Connected from "../service/etc/Connected";
import Disconnect from "../service/etc/Disconnect";
import { app_name, project_id } from "../services/AppSetting/redux";
import { setConnectionType } from "../services/Connection/reedux";
import { user_access, user_refresh_token } from "../services/User/redux";
import { CONNECTION_TYPE } from "../Utils/constant";
import { connectionReferrer } from "../Utils/referrer";

import { useDispatch, useSelector } from "react-redux/es";
import { changeWordsByAppName } from "../locales";

import { controllers } from "controllers";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router";
import { metadataSetting } from "Utils/metadata";

function RootRouter(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const projectId = Number(urlParams.get("projectId"));
  const dispatch = useDispatch();
  const location = useLocation();
  const userAccess = useSelector(user_access);

  const [isLoading, setIsLoading] = useState(true);
  //리덕스 가져오는거
  const appName = useSelector(app_name);
  const project = useSelector(project_id);

  const NotFound = () => {
    return <div id="not-found">404</div>;
  };

  const requestFrom = useMemo(() => {
    switch (location.pathname.split("/")[1]) {
      case "connection":
        return "EQBR";
      case "OAuth":
        return "OAuth";
      default:
        return null;
    }
  }, [location]);

  useEffect(() => {
    const createRefreshToken = async () => {
      if (connectionReferrer.includes(`${document.referrer}`)) {
        const result = await controllers.user.refreshToken(project);
        dispatch(user_refresh_token(result)).finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        });
      } else {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    };

    createRefreshToken();
  }, [project]);

  //projectId , appName
  const initialize_ = () => {
    const { result }: any =
      controllers.appSetting.useUpdateAppSetting(projectId);
    if (result) {
      const { appName } = result;
      changeWordsByAppName(appName);
    }
  };
  const setAppNameInHTMLAttribute = (appName: string) => {
    document.documentElement.setAttribute("data-project", appName || "whisper");
  };
  useEffect(() => {
    initialize_();
  }, [projectId]);

  useEffect(() => {
    changeWordsByAppName(appName);
    setAppNameInHTMLAttribute(appName);
  }, [appName]);

  //metadata 설정
  useEffect(() => {
    if (appName) {
      metadataSetting.changeDesc(appName);
      metadataSetting.changeFavicon(appName);
      metadataSetting.changeManifest(appName);
      metadataSetting.changeTitle(appName);
      metadataSetting.changeTouchIcon(appName);
      metadataSetting.changeTouchIcon512(appName);
    }
  }, [appName, projectId]);

  return (
    <Routes>
      <Route path={"/*"} element={<Layout />}>
        <Route
          element={
            <ConnectionRouter
              isLoading={isLoading}
              userAccess={userAccess}
              requestFrom={requestFrom}
            />
          }
        >
          {appName ? (
            <Route
              path="connection/*"
              element={<ConnectionMain requestFrom={requestFrom} />}
            />
          ) : (
            <Route path="*" element={<NotFound />} />
          )}
          {appName ? (
            <Route
              path="OAuth/*"
              element={<ConnectionMain requestFrom={requestFrom} />}
            />
          ) : (
            <Route path="*" element={<NotFound />} />
          )}
        </Route>
        <Route path="signing-request/*" element={<SigningRequest />} />
      </Route>
      <Route path="connected" element={<Connected />} />
      <Route path="disconnect" element={<Disconnect />} />
    </Routes>
  );
}

export default RootRouter;

const ConnectionRouter = ({ isLoading, userAccess, requestFrom }) => {
  const dispatch = useDispatch();

  if (isLoading) {
    return <PageLoading />;
  } else {
    if (Boolean(userAccess)) {
      if (requestFrom === "EQBR") {
        return <Navigate to="/connected" />;
      } else {
        dispatch(
          setConnectionType(CONNECTION_TYPE.CONNECT_CHECK_OAUTH_PERMISSION)
        );
        return <Outlet />;
      }
    } else {
      dispatch(setConnectionType(CONNECTION_TYPE.CONNECT_TYPE_SELECT));
      return <Outlet />;
    }
  }
};
