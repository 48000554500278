import { serverResponseInterceptor } from "controllers/serverAuthorization";
import { actionController, ActionType } from "controllers/utils";
import { services } from "services";

const { connection } = services;

export const ConnectionController = {
  createConnection: (
    domain: string,
    projectId: number
  ): Promise<ActionType<any>> =>
    actionController(async () => {
      const { data } = await serverResponseInterceptor(
        connection.server.createConnection,
        {
          data: {
            domain,
            projectId,
          },
        }
      );
      return data;
    }),

  pollingConnection: (
    projectId: number,
    connectionId: number
  ): Promise<ActionType<any>> =>
    actionController(async () => {
      const { data } = await serverResponseInterceptor(
        connection.server.pollingConnection,
        {
          query: {
            connectionId,
          },
          data: { projectId },
        }
      );
      return data;
    }),
};
