export const connectionReferrer = [
  // 'https://gateway.dev.eqhub.eqbr.com/',
  // 'https://gateway.eqhub.eqbr.com/',
  // 'https://demo-gateway.dev.eqhub.eqbr.com/',
  // 'http://localhost:3000/',
  // 'https://zvengers.dev.eqhub.eqbr.com/',
  // 'https://demo-nft.dev.eqhub.eqbr.com',
  // 'http://192.168.0.51:3000',
  // 'pg-jfwf.dev.eqbr.com',
  // 'pg-jfwf.eqbr.com',
  'https://volare.network'
]
