import "./PageLoading.scss";
import React from "react";

export default function PageLoading() {
	return (
		<div className={"page-loading"}>
			<img alt="loading" className={"page-loading-icon loadingIcon"}/>
		</div>
	);
}
