import { servers } from "./server";

export const services = {
  singingRequest: {
    server: servers.signingRequest,
  },
  connection: {
    server: servers.connection,
  },
  user: {
    server: servers.user,
  },
};
