import { serverResponseInterceptor } from "controllers/serverAuthorization";
import { actionController, ActionType } from "controllers/utils";
import { services } from "services";

const { user } = services;

export const UserController = {
  getOAuthServiceDetail: (clientId: number): Promise<ActionType<any>> =>
    actionController(async () => {
      const { data } = await serverResponseInterceptor(
        user.server.fetchOAuthServiceDetail,
        {
          query: {
            clientId,
          },
        }
      );
      return data;
    }),

  OAuth: (
    redirectUri: string,
    clientId: number,
    responseType: any,
    scope: any,
    userAccess: string
  ): Promise<ActionType<any>> =>
    actionController(async () => {
      const { data } = await serverResponseInterceptor(
        user.server.OAuth,
        {
          query: {
            redirect_uri: redirectUri,
            client_id: clientId,
            response_type: responseType,
            scope,
          },
        },
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `Bearer ${userAccess}`,
          },
        }
      );
      return data;
    }),

  logout: (projectId: number): Promise<ActionType<any>> =>
    actionController(async () => {
      const { data } = await serverResponseInterceptor(user.server.logout, {
        query: {
          projectId,
        },
      });

      return data;
    }),

  refreshToken: (projectId: number): Promise<ActionType<any>> =>
    actionController(async () => {
      const { data } = await serverResponseInterceptor(
        user.server.refreshToken,
        {
          data: {
            projectId,
          },
        }
      );

      return data;
    }),
};
