import WhisperColors from "./_colors.whisper.scss";
import EONColors from "./_colors.eon.scss";
import BonegoColors from "./_colors.bonego.scss";
import { AppName } from "env";

export const getColorsByProjectId = (appName) => {
  switch (appName) {
    case AppName.whisper:
      return WhisperColors;
    case AppName.EON:
      return EONColors;
    case AppName.bonego:
      return BonegoColors;
    default:
      return WhisperColors;
  }
};
