import METADATA from "env";

export const metadataSetting = {
  changeFavicon: (appName: string) => {
    if (typeof window === "undefined") return;
    const link =
      (window.document.querySelector("link[rel*='icon']") as HTMLLinkElement) ||
      window.document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "icon";
    link.href = METADATA[appName].favicon;
    document.head.appendChild(link);
  },
  changeTouchIcon: (appName: string) => {
    if (typeof window === "undefined") return;
    const link =
      (window.document.querySelector(
        "link[rel*='apple-touch-icon']"
      ) as HTMLLinkElement) || window.document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "apple-touch-icon";

    link.href = METADATA[appName].logo192;
    document.head.appendChild(link);
  },
  changeTouchIcon512: (appName: string) => {
    if (typeof window === "undefined") return;
    const link =
      (window.document.querySelector(
        "link[rel*='apple-touch-icon']"
      ) as HTMLLinkElement) || window.document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "apple-touch-icon";

    link.href = METADATA[appName].logo512;
    document.head.appendChild(link);
  },
  changeTitle: (appName: string) => {
    if (typeof window === "undefined") return;
    document.title = METADATA[appName].title;
  },
  changeDesc: (appName: string) => {
    if (typeof window === "undefined") return;
    const metaDescription =
      (document.querySelector("meta[name='description']") as HTMLMetaElement) ||
      document.createElement("meta");
    ``;
    metaDescription.name = "description";

    metaDescription.content = METADATA[appName].description;
    document.head.appendChild(metaDescription);
  },
  changeManifest: (appName: string) => {
    if (typeof window === "undefined") return;
    const manifest =
      (window.document.querySelector(
        "link[rel*='manifest']"
      ) as HTMLLinkElement) || window.document.createElement("link");
    manifest.type = "application/manifest+json";
    manifest.rel = "manifest";

    manifest.href = METADATA[appName].manifest;
    document.head.appendChild(manifest);
  },
};
