import { CredentialObserver } from "serviceUtils/ct-library-credential/index.type";
import {
  Api,
  ContentType,
  RequestParams,
} from "serviceUtils/ct-library-http-client";
import {
  createConnectionRequest,
  createConnectionResponse,
  pollingConnectionRequest,
  pollingConnectionResponse,
} from "./index.type";

export class SigningRequestAPI extends Api implements CredentialObserver {
  constructor(baseUrl: string) {
    super({ baseUrl });
  }

  updateAccessToken(access_token: string): void {
    this.updateHeaders({ Authorization: `Bearer ${access_token}` });
  }
  onAccessTokenChange(access_token: string) {
    this.updateAccessToken(access_token);
  }
  updateApiKey(api_key: string): void {
    this.updateHeaders({ "x-eq-ag-api-key": api_key });
  }

  onAPIKeyChange(api_key: string) {
    this.updateApiKey(api_key);
  }
  /**
   * No description
   *
   * @name createConnection
   * @summary Connection
   * @request POST:/
   */
  createConnection = async (
    request: createConnectionRequest,
    params: RequestParams = {}
  ) =>
    await this.request<createConnectionResponse, Error>({
      path: `/users/connection`,
      method: "POST",
      format: "json",
      type: ContentType.Json,
      body: request.data,
      ...params,
    });

  /**
   * No description
   *
   * @name pollingConnection
   * @summary Connection
   * @request POST:/
   */
  pollingConnection = async (
    request: pollingConnectionRequest,
    params: RequestParams = {}
  ) =>
    await this.request<pollingConnectionResponse, Error>({
      path: `/users/connection/${request.query.connectionId}/poll`,
      method: "POST",
      format: "json",
      type: ContentType.Json,
      body: request.data,
      ...params,
    });
}
export const connectionServer = new SigningRequestAPI(
  process.env.REACT_APP_CONNECT_WEB_END_POINT as string
);
