import { HttpResponse } from "../serviceUtils/ct-library-http-client";

const onAPIUnauthorized = async (
  fn: (arg?: any, params?: any) => Promise<HttpResponse<any, Error>>,
  arg?: any,
  params?: any
) => {
  try {
    return await fn(arg, params);
  } catch (e) {
    if (e.status === 401) {
      try {
      } catch (error) {
        if (error.status === 401) {
        }
      }
    }
    throw e;
  }
};

export const serverResponseInterceptor = async <T>(
  fn: (arg: any, params?: any) => Promise<HttpResponse<T, Error>>,
  arg?: any,
  params?: any
): Promise<HttpResponse<T, Error>> => {
  return await onAPIUnauthorized(fn, arg, params);
};
