import { AppName } from "../../../env";

export const makeAppNameFromQueryUtil = (projectId) => {
  let appName;

  switch (projectId) {
    case Number(process.env.REACT_APP_WHISPER_PROJECT_ID):
      appName = AppName.whisper;
      break;
    case Number(process.env.REACT_APP_EON_PROJECT_ID):
      appName = AppName.EON;
      break;
    case Number(process.env.REACT_APP_BONEGO_PROJECT_ID):
      appName = AppName.bonego;
      break;
    default:
      appName = undefined;
  }

  return { projectId, appName };
};
