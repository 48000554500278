import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { logout } from "../../services/User/server";
import { useDispatch, useSelector } from "react-redux";
import PageLoading from "../../common/atom/loading/PageLoading";
import { project_id } from "../../services/AppSetting/redux";
import { controllers } from "controllers";

function Disconnect(props) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const project = useSelector(project_id);

  const logout_ = async (project) => {
    try {
      const { result, error } = await controllers.user.logout(project);
      toast.success("You have successfully disconnected!");
      if (error) {
        console.log(error);
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      logout_(project);
    }
  }, [isLoading]);

  if (isLoading) {
    return <PageLoading />;
  } else {
    return <div></div>;
  }
}

export default Disconnect;
