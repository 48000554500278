import { createSlice } from "@reduxjs/toolkit";

// Adapter 영역 - 선언부

const initialState = {
  projectId: 0,
  appName: "",
};
// Slice 영역 - 선언부
const appSettingSlice = createSlice({
  name: "appSetting",
  initialState,
  reducers: {
    setAppName(state, action) {
      state.appName = action.payload;
    },
    setProjectId(state, action) {
      state.projectId = action.payload;
    },
  },
});

// Action 영역
export const { setAppName } = appSettingSlice.actions;
export const { setProjectId } = appSettingSlice.actions;

// Store 영역
export const app_name = (state) => state.appSetting.appName;
export const project_id = (state) => state.appSetting.projectId;

// Reducer 영역
export default appSettingSlice.reducer;
