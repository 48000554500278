export type ApiErrorData = {
  data: null;
  error: {
    code: string;
    message: string;
  };
  body: {
    locked: boolean;
  };
  bodyUsed: boolean;
  headers: object;
  ok: boolean;
  redirected: boolean;
  status: number;
  statusText: string;
  type: string;
  url: string;
};

export type ApiError = {
  type: "API";
  data: ApiErrorData;
};

type MessageError = {
  message: string;
};

type ErrorType = "API" | "Realm" | "message" | "unknown";

type ActionErrorType = { type: ErrorType; data: any };
export type ActionType<T> =
  | {
      result: T;
      error: undefined;
    }
  | {
      result: undefined;
      error: ActionErrorType;
    };

export const isMessageError = (error: any): error is MessageError => {
  return !!error.message;
};

export const isApiError = (response: any): boolean => {
  // console.log(response?.error?.error);
  // export const isApiError = (error: any): error is ApiError => {
  return response?.error?.error;
};

export const actionController = async <T>(
  fn: () => Promise<T>
): Promise<ActionType<T>> => {
  try {
    const result = await fn();
    return { result, error: undefined };
  } catch (e: unknown) {
    let actionError: ActionErrorType;
    if (isApiError(e)) {
      // const apiError = e as ApiErrorData;
      actionError = {
        type: "API",
        data: e,
      };
    } else if (isMessageError(e)) {
      actionError = {
        type: "message",
        data: e.message,
      };
    } else {
      actionError = {
        type: "unknown",
        data: e,
      };
    }
    return {
      result: undefined,
      error: actionError,
    };
  }
};

// -------------------------------- middleware ---------------------------
type Controller = (...args: any[]) => any;
type MiddlewareFunction = (controller: Controller, ...args: any[]) => void;
export type AppControllers = { [k: string]: { [p: string]: Controller } };
export type DomainControllers = { [k: string]: Controller };
export const controllerMiddleware = <T>(
  middlewares: MiddlewareFunction[],
  controllers: T & DomainControllers
): T & DomainControllers => {
  const controllerNameArray = Object.keys(controllers);
  let controllersWithMiddleware = {
    ...controllers,
  };
  controllerNameArray.forEach((name) => {
    controllersWithMiddleware = {
      ...controllersWithMiddleware,
      [name]: (...args: any[]): Controller => {
        middlewares.forEach((middleware) => {
          middleware(controllers[name], args);
        });
        return controllers[name](...args);
      },
    };
  });
  return controllersWithMiddleware;
};

//reactotron

// export const loggerForController = (fn: Controller, args: any) => {
//   Logger.custom({
//     name: "Controller",
//     preview: fn.name,
//     value: {
//       name: fn.name,
//       args: args ?? "no arguments",
//     },
//     important: true,
//   });
//   return fn;
// };
