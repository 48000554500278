import { Action, configureStore } from "@reduxjs/toolkit";
import userReducer from "./User/redux";
import connectionSlice from "./Connection/reedux";
import appSettingReducer from "./AppSetting/redux";

export const store = configureStore({
  reducer: {
    user: userReducer,
    connection: connectionSlice,
    appSetting: appSettingReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export const RootState = store.getState();
export const storeDispatch = (action) => store.dispatch(action);
export const storeState = () => store.getState();
