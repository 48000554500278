import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { app_name } from "../../../services/AppSetting/redux";
import { getColorsByProjectId } from ".";

export default function usePalette() {
  const appName = useSelector(app_name);
  const [colors, setColors] = useState(getColorsByProjectId(appName));

  useEffect(() => {
    const colors = getColorsByProjectId(appName);
    setColors(colors);
  }, [appName]);

  return colors;
}
