import {BrowserRouter} from "react-router-dom";
import RootRouter from "./router/RootRouter";
import React from "react";
import {Toaster} from "react-hot-toast";
import './locales/index';

export default function App() {
  return (
    <>
      <BrowserRouter>
        <RootRouter/>
      </BrowserRouter>
      <Toaster/>
    </>
  );
}
