import React from 'react';
import './ConnectModuleTemplate.scss'

function ConnectModuleTemplate({children, onClickClose, userAccess}) {
    return (
        <div className='connect-module-template'>
            {children}
        </div>
    );
}

export default ConnectModuleTemplate;