import { actionController, ActionType } from "controllers/utils";
import { storeDispatch } from "services/store";
import { setAppName, setProjectId } from "../../services/AppSetting/redux";
import { makeAppNameFromQueryUtil } from "./utils/projectIdUtil";

const AppSettingController = {
  useUpdateAppSetting: (projectId: number): Promise<ActionType<any>> =>
    actionController(async () => {
      const appSettingData = makeAppNameFromQueryUtil(projectId);
      const appName = appSettingData.appName;

      storeDispatch(setAppName(appName));
      storeDispatch(setProjectId(projectId));

      return { projectId, appName };
    }),
};
export default AppSettingController;
