import { serverResponseInterceptor } from "controllers/serverAuthorization";
import { actionController, ActionType } from "controllers/utils";
import { services } from "services";

const { singingRequest } = services;
// TODO :: 사용하지 않음
export const SigningRequestController = {
  createSigningRequest: (rest: any, token: any): Promise<ActionType<any>> =>
    actionController(async () => {
      const { data } = await serverResponseInterceptor(
        singingRequest.server.createSigningRequest,
        {
          data: {
            rest,
          },
        },
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    }),

  pollingSigningResult: (
    pollingId: number,
    token: string
  ): Promise<ActionType<any>> =>
    actionController(async () => {
      const { data } = await serverResponseInterceptor(
        singingRequest.server.pollingSigningResult,
        {
          params: {
            pollingId,
          },
        },
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    }),
};
