import _ from "lodash";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  MESSAGE_TARGET,
  SIGNING_REQUEST_POLLING_STATUS,
} from "../../Utils/constant";
import PageLoading from "../../common/atom/loading/PageLoading";

import { controllers } from "controllers";
import "./SigningRequest.scss";

function SigningRequest(props) {
  const dispatch = useDispatch();
  const [dataForRequestSigning, setDataForRequestSigning] = useState(null);
  const [pollingId, setPollingId] = useState(null);
  const [pollingStatus, setPollingStatus] = useState(null);
  const [signingResult, setSigningResult] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const createSigningRequest_ = async () => {
    const { token, ...rest } = dataForRequestSigning;
    try {
      const { polling_id, error } =
        await controllers.signingRequest.createSigningRequest(rest, token);
      setPollingId(polling_id);
      setPollingStatus(SIGNING_REQUEST_POLLING_STATUS.POLLING_PENDING);
    } catch (e) {
      throw e;
    }
  };

  const pollingController = async () => {
    //long polling을 유지하는 함수
    try {
      const txResult = await controllers.signingRequest.pollingSigningResult(
        pollingId,
        dataForRequestSigning.token
      );
      let signingResult;
      if (txResult.status) {
        signingResult = {
          status: SIGNING_REQUEST_POLLING_STATUS.POLLING_SUCCESS,
          data: txResult,
        };
      } else {
        signingResult = {
          status: SIGNING_REQUEST_POLLING_STATUS.POLLING_FAIL,
          data: txResult,
        };
      }
      setSigningResult(signingResult);
    } catch (e) {
      if (e.response instanceof XMLHttpRequest) {
        await pollingController();
      } else {
        console.error("server return false");
      }
    }
  };

  useEffect(() => {
    //부모 window로 부터 signing request에 필요한 데이터를 postMessage로 받는 로직
    function receiveMessage(event) {
      if (event.data.type === MESSAGE_TARGET.EQBR_SIGNING_REQUEST) {
        const { signingData } = event.data.payload;
        if (_.isEmpty(signingData)) {
          toast.error("invalid request");
        } else {
          setDataForRequestSigning(signingData);
        }
      } else {
        return null;
      }
    }

    window.addEventListener("message", receiveMessage);
    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  }, []);

  useEffect(() => {
    if (isLoading) {
      if (dataForRequestSigning) {
        createSigningRequest_()
          .then(() => {
            setIsLoading(false);
          })
          .catch((e) => {
            toast.error("Sorry, Please try again");
          });
      }
    }
  }, [dataForRequestSigning, isLoading]);

  useEffect(() => {
    if (Boolean(pollingId) && Boolean(pollingStatus)) {
      pollingController();
    }
  }, [pollingId, pollingStatus]);

  useEffect(() => {
    if (signingResult) {
      window.opener.postMessage(
        {
          target: MESSAGE_TARGET.EQBR_SIGNING_POLLING_RESPONSE,
          payload: { ...signingResult },
        },
        "*"
      );
      window.close();
    }
  }, [signingResult]);

  if (isLoading) {
    return <PageLoading />;
  } else {
    return (
      <div id="SigningRequest">
        <div className="signing-request-text-container">
          <div className="signing-request-text">Processing...</div>
          <div className="signing-request-text">This may take some time.</div>
          <div className="signing-request-text bold">
            Do not close the window
          </div>
        </div>
        <img className="signing-request-loading loadingIcon" alt="loading" />
        <img className="whisper-logo companyLogoWithText" alt="logo" />
      </div>
    );
  }
}

export default SigningRequest;
