import AppSettingController from "./AppSetting/AppSettingController";
import { ConnectionController } from "./Connection/ConnectionController";
import { SigningRequestController } from "./Signing-request/SiginingRequestController";
import { UserController } from "./User/UserController";

export const controllers = {
  signingRequest: SigningRequestController,
  connection: ConnectionController,
  user: UserController,
  appSetting: AppSettingController,
};
