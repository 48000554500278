import {CredentialObserver} from "./index.type";

export class Credential {
  access_token: string | undefined;
  api_key: string | undefined;
  credentialObservers: CredentialObserver[];

  constructor(credentialObservers: {[k: string]: CredentialObserver}) {
    this.credentialObservers = Object.keys(credentialObservers).map(
      (key) => credentialObservers[key],
    );
  }

  private announceChangeAuthorization() {
    for (let i = 0; i < this.credentialObservers.length; i++) {
      this.credentialObservers[i].onAccessTokenChange(
        this.access_token as string,
      );
    }
  }

  private announceChangeApiKey() {
    for (let i = 0; i < this.credentialObservers.length; i++) {
      this.credentialObservers[i].onAPIKeyChange(this.api_key as string);
    }
  }

  setAccessToken(access_token: string) {
    this.access_token = access_token;
    this.announceChangeAuthorization();
    return this.access_token;
  }

  deleteAccessToken() {
    this.access_token = undefined;
    this.announceChangeAuthorization();
    return this.access_token;
  }

  getAccessToken() {
    return this.access_token;
  }

  setApiKey(api_key: string) {
    this.api_key = api_key;
    this.announceChangeApiKey();
    return this.api_key;
  }

  deleteApiKey() {
    this.api_key = undefined;
    this.announceChangeApiKey();
    return this.api_key;
  }

  getApiKey() {
    return this.api_key;
  }
}
