import { CredentialObserver } from "serviceUtils/ct-library-credential/index.type";
import { Api, RequestParams } from "serviceUtils/ct-library-http-client";
import {
  pollingSigningResultRequest,
  pollingSigningResultResponse,
  createSigningRequest,
} from "./index.type";

export class SigningRequestAPI extends Api implements CredentialObserver {
  constructor(baseUrl: string) {
    super({ baseUrl });
  }

  updateAccessToken(access_token: string): void {
    this.updateHeaders({ Authorization: `Bearer ${access_token}` });
  }
  onAccessTokenChange(access_token: string) {
    this.updateAccessToken(access_token);
  }
  updateApiKey(api_key: string): void {
    this.updateHeaders({ "x-eq-ag-api-key": api_key });
  }

  onAPIKeyChange(api_key: string) {
    this.updateApiKey(api_key);
  }
  /**
   * No description
   *
   * @name createSigningRequest
   * @summary User
   * @request POST:/
   */
  createSigningRequest = async (
    request: createSigningRequest,
    params: RequestParams = {}
  ) =>
    await this.request<any, Error>({
      path: `/signing-requests`,
      method: "POST",
      format: "json",
      body: request.data,
      credentials: "include",
      ...params,
    });

  /**
   * No description
   *
   * @name  pollingSigningResult
   * @summary User
   * @request GET:/
   */
  pollingSigningResult = async (
    request: pollingSigningResultRequest,
    params: RequestParams = {}
  ) =>
    await this.request<pollingSigningResultResponse, Error>({
      path: `signing-requests/${request.params.pollingId}/polling`,
      method: "GET",
      format: "json",
      credentials: "include",
      ...params,
    });
}
export const signingRequestServer = new SigningRequestAPI(
  process.env.REACT_APP_CONNECT_WEB_END_POINT as string
);
