import { controllers } from "controllers";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useTranslationCustom from "../../locales/useTranslationCustom";
import { user_access } from "../../services/User/redux";
import "./CheckOAuthPermission.scss";

function CheckOAuthPermission() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userAccess = useSelector(user_access);

  const { t } = useTranslationCustom({
    prefix: "",
    nameSpace: "oauth",
  });

  const { t: s } = useTranslationCustom({
    prefix: "scope",
    nameSpace: "oauth",
  });

  const [clientData, setClientData] = useState({
    clientId: "",
    responseType: "",
    redirectUri: "",
  });
  const [permission, setPermission] = useState({
    needPermission: false,
    agreePermission: false,
    scopeData: {},
  });
  const [oauthServiceDetail, setOAuthServiceDetail] = useState({
    name: "",
    image: "",
  });
  const OAuth_ = async ({ redirectUri, clientId, responseType, scope }) => {
    try {
      return await controllers.user.OAuth(
        redirectUri,
        clientId,
        responseType,
        scope,
        userAccess
      );
    } catch (e) {
      toast.error(e.message);
    }
  };

  const checkOAuthAfterAccess = async () => {
    const OAuthResult = await OAuth_({
      ...clientData,
    });
    if (OAuthResult.result.scopeData) {
      setPermission((state) => ({
        ...state,
        needPermission: true,
        scopeData: OAuthResult.result.scopeData,
      }));
    }
    if (OAuthResult.result.status_code === 302) {
      if (window.opener) {
        setTimeout(() => {
          window.opener.location.href = OAuthResult.result.redirect_uri;
          window.close();
        }, 800);
        return;
      }
      setTimeout(() => {
        window.location.href = OAuthResult.result.redirect_uri;
      }, 800);
    }
  };

  const checkOAuthAfterAgreePermission = async () => {
    const OAuthResult = await OAuth_({
      ...clientData,
      scope: permission.scopeData.reduce(
        (previous, current) =>
          previous + " " + (current.scope && current.scope),
        ""
      ),
    });
    if (OAuthResult.result.status_code === 302) {
      if (window.opener) {
        setTimeout(() => {
          window.opener.location.href = OAuthResult.result.redirect_uri;
          window.close();
        }, 800);
        return;
      }
      setTimeout(() => {
        window.location.href = OAuthResult.result.redirect_uri;
      }, 800);
    }
  };

  const getOAuthServiceDetailFromServer = async () => {
    const { result } = await controllers.user.getOAuthServiceDetail(
      clientData.clientId
    );
    setOAuthServiceDetail({
      name: result.service_name,
      image: result.service_image,
    });
  };

  const agreePermission = () => {
    setPermission((state) => ({
      ...state,
      agreePermission: true,
    }));
  };

  useEffect(() => {
    if (userAccess) {
      if (
        Boolean(params.get("client_id")) &&
        Boolean(params.get("response_type")) &&
        Boolean(params.get("redirect_uri"))
      ) {
        setClientData({
          clientId: params.get("client_id"),
          responseType: params.get("response_type"),
          redirectUri: params.get("redirect_uri"),
        });
      } else {
        toast.error("Invalid Access");
      }
    } else {
      toast.error("Invalid Access");
    }
  }, [userAccess, location]);

  useEffect(() => {
    //clientData가 정상일 경우 로그인 한 유저가 OAuth에 등록된 유저인지 아닌지 체크
    if (userAccess && !Object.values(clientData).includes("")) {
      checkOAuthAfterAccess();
    }
  }, [userAccess, clientData]);

  useEffect(() => {
    if (permission.agreePermission) {
      checkOAuthAfterAgreePermission();
    }
  }, [permission]);

  useEffect(() => {
    if (permission.needPermission && Boolean(clientData.clientId)) {
      getOAuthServiceDetailFromServer();
    }
  }, [permission, clientData]);
  return (
    <>
      {permission.needPermission ? (
        <div className="need-oauth-permission">
          <div className="need-oauth-permission-top">
            <span className="need-oauth-permission-top-header">
              {t("title")}
            </span>
            <div className="need-oauth-permission-top-service">
              <img
                src={oauthServiceDetail.image}
                alt={"service-img"}
                className="need-oauth-permission-top-service-image"
              />
              <div className="need-oauth-permission-top-service-name">
                {oauthServiceDetail.name}
              </div>
            </div>
            <div className="need-oauth-permission-top-scope">
              {permission.scopeData.map((el, index) => {
                return (
                  <div className={"need-oauth-permission-top-scope-data"}>
                    <div className="need-oauth-permission-top-scope-data-name">
                      {s(`${el.scope}.name`) ?? s("default.name")}
                    </div>
                    <div className="need-oauth-permission-top-scope-data-description">
                      {s(`${el.scope}.description`) ?? s("default.description")}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="need-oauth-permission-bottom">
            <button
              className="need-oauth-permission-bottom-button"
              onClick={() => agreePermission()}
            >
              {t("button")}
            </button>
            <span className="need-oauth-permission-bottom-comment">
              {t("comment")}
            </span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default CheckOAuthPermission;
