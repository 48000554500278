import React, {useEffect} from "react";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import {user_access} from "../../services/User/redux";
import {app_name} from "../../services/AppSetting/redux";

function Connected(props) {
	const userAccess = useSelector(user_access);
	const appName = useSelector(app_name);

	useEffect(() => {
		if (userAccess) {
			toast.success(`You are already connected by ${appName}!`);
			setTimeout(() => {
				window.close();
			}, 1000);
		} else {
			toast.success("Required Connection.");
		}
	}, [userAccess]);

	return <div></div>;
}

export default Connected;
